import React, { useEffect, useState } from 'react';
import { graphql } from "gatsby"

import SEO from "../components/SEO/SEO"
import Breadcrumb from '../components/Breadcrumb/Breadcrumb'
import FilterGroup from '../components/Filter/FilterGroup'
import BackToTop from '../components/BackToTop/BackToTop'

const CategoryTemplate = ( { data, location, pageContext } ) => {

	const categoryTags = data.strapi.categoryTags
	const manufacturers = data.strapi.manufacturers
	const dataTags = data.strapi.tags
	const [ allStrapiTags, setAllStrapiTags ] = useState( dataTags )

	/**
	 * This merges the child tags into the all tags array, this is so the names and ID's can be accessed
	 * prevents bringing in ALL tags on the template query, unlike the products page where all tags are pulled in
	 */
	useEffect(() => {
		let childTags = []

		dataTags.forEach( tag => {
			if ( tag.tags.length ) {
				childTags.push( ...tag.tags )
			}
		} )

		setAllStrapiTags( state => ([
			...state,
			...childTags
		]) )
	}, [ dataTags ])

	return (
		<>
			<SEO
				title={ pageContext.name }
				pathname={ location.pathname } />
			<Breadcrumb pathname={ location.pathname } />
			<FilterGroup
				categoryIds={ pageContext.id }
				categoryTags={ categoryTags }
				manufacturers={ manufacturers }
				allStrapiTags={ allStrapiTags } />

			<BackToTop />
		</>
	)
}

export default CategoryTemplate

export const query = graphql`
	query Filters( $number: Int ) {
		strapi {
			categoryTags( where: { category: { id: $number }} ) {
				label
				tags {
					id
					name
				}
			}
			manufacturers( where: { categories: { id: $number }} ) {
				id
				name
			}
			tags( where: { category_tags: { category: {id: $number }}} ) {
				id
				name
				tags {
					id
					name
				}
			}
		}
	}
`